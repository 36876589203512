<template>
	<section class="gears py-6" :class="'gears--arrangement-'+arrangement">
		<div class="wrapper" :class="`wrapper--${wrapper}`">
			<slot />
		</div>
		<div class="gears__svgs b6:show" :class="`${gearColor}`">
			<svg class="gears__sm spin"><use xlink:href="#small-gear" /></svg>
			<svg class="gears__md spin"><use xlink:href="#small-gear" /></svg>
			<svg class="gears__lg spin"><use xlink:href="#big-gear" /></svg>
			<svg class="gears__xl spin"><use xlink:href="#big-gear" /></svg>

			<svg xmlns="http://www.w3.org/2000/svg">
				<defs>
					<symbol
						id="big-gear" viewBox="0 0 480 480" fill="transparent" style="stroke: currentcolor;
						stroke-width: 2px;
						enable-background: new 0 0 480 480;" xml:space="preserve"
					>
						<path
							d="M480,269.6v-60.1l-67.4-12.1c-4.8-17.3-11.2-33.7-20.7-49.3l38.9-56.6l-42.3-42.3L331.8,89
					c-16.8-9.9-48.4-19.9-48.4-19.9L270.9,0h-60.1l-12.5,69.1c-17.3,4.8-33.3,10.4-48,19.4L93.8,49.7L51.4,89.4l38.5,60.5
					C80.4,165,73.9,181,70,198.3L0.9,210.4v60.5l68.7,12.5c4.7,17.4,11,33.1,20.2,48.3c-0.2,0.3-38.4,56-38.4,56l41.5,43.4l57-38.9
					c15.1,9.5,31.1,16,48.4,20.7l12.1,66.5h60.1l12.1-68.7c17.3-4.3,34.1-11.2,49.3-19.9l56.6,38.9l42.3-42.3l-38.9-56.6
					c9.1-15.1,16-31.5,20.7-49.3L480,269.6z M240,330c-49.7,0-90-40.3-90-90s40.3-90,90-90s90,40.3,90,90S289.7,330,240,330z"
						/>
					</symbol>
					<symbol
						id="small-gear" viewBox="0 0 439 430" fill="transparent" style="stroke: currentcolor;
						stroke-width: 2px;
						enable-background: new 0 0 439 430;" xml:space="preserve"
					>
						<path d="M172.485 67.8747L184.6 0L244.3 1.2L256.242 65.7323C277.326 71.0947 296.647 80.7984 313.216 93.8213L371 63.1992L408.6 110.499L362.495 161.246C368.142 176.415 371.462 192.702 372.029 209.665L436.3 237.5L423.1 296.5L351.858 291.291C344.099 304.836 334.341 317.078 322.992 327.622L344.8 399.3L291.1 426.9L246.424 366.165C236.575 367.983 226.4 368.86 216 368.701C209.35 368.607 202.804 368.092 196.388 367.183L153.2 429.1L97.7002 403.8L118.149 331.764C104.873 320.388 93.5672 306.794 84.7984 291.565L14.9 299.501L0 241.401L64.3924 213.184C64.3946 212.99 64.3972 212.795 64.4002 212.601C64.6596 194.226 68.1293 176.651 74.2678 160.401L28.4004 110.1L64.7004 62L121.863 94.8554C136.729 82.902 153.856 73.6623 172.485 67.8747ZM154.5 214.901C154.5 250.101 183 278.601 218.2 278.601C253.4 278.601 281.9 250.101 281.9 214.901C281.9 179.701 253.4 151.201 218.2 151.201C183 151.201 154.5 179.701 154.5 214.901Z" />
					</symbol>
				</defs>
			</svg>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'CGears',
		props: {
			wrapper: {
				type: String,
				default: '3'
			},
			gearColor: {
				type: String,
				default: 'deep'
			},
			arrangement: {
				type: String,
				default: '1'
			}
		}
	};
</script>

<style lang="scss">
	.gears {
		position: relative;
		overflow: hidden;

		// Position everything inside the gears container above the gears.
		& > .wrapper {
			position: relative;
			z-index: $z1;
		}

		&__svgs {
			position: static;
		}

		&__svgs svg {
			position: absolute;
			z-index: 0;
		}

		&--arrangement-1 {

			.gears {

				&__xl {
					bottom: -15em;
					left: -15em;
					width: 35em;
					height: 35em;
					animation-duration: 60s;
				}

				&__lg {
					left: calc(50% - 15em);
					top: -4.5em;
					width: 8em;
					height: 8em;
					animation-duration: 8s;
				}

				&__md {
					bottom: 3.7em;
					left: 90%;
					width: 21em;
					height: 21em;
					animation-duration: 15s;
					animation-direction: reverse;
				}

				&__sm {
					bottom: -7.8em;
					left: calc(90% - 13.6em);
					width: 21em;
					height: 21em;
					animation-duration: 15s;
				}
			}
		}

		&--arrangement-2 {

			.gears {

				&__xl {
					bottom: -25em;
					right: -30em;
					width: 50em;
					height: 50em;
					animation-duration: 60s;
				}

				&__lg {
					bottom: 6em;
					right: 83%;
					width: 30em;
					height: 30em;
					animation-duration: 20s;
				}

				&__md {
					left: 93%;
					top: 8em;
					width: 13em;
					height: 13em;
					animation-duration: 15s;
					animation-direction: reverse;
				}

				&__sm {
					left: calc(93% - 6.3em);
					top: 16em;
					width: 10.125em;
					height: 10.125em;
					animation-duration: 15s;
				}
			}
		}

		&--arrangement-3 {

			.gears {

				&__xl {
					bottom: -35em;
					right: -30em;
					width: 50em;
					height: 50em;
					animation-duration: 60s;
				}

				&__md {
					bottom: 1em;
					right: calc(100% - 300px);
					width: 25em;
					height: 25em;
					animation-duration: 20s;
				}

				&__lg {
					bottom: -30em;
					right: calc(100% - 650px);
					width: 35em;
					height: 35em;
					animation-duration: 20s;
					animation-direction: reverse;
				}

				&__sm {
					bottom: -14em;
					right: calc(100% - 100px);
					width: 15em;
					height: 15em;
					animation-duration: 5s;
				}
			}
		}
	}
</style>